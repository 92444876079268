.header_bar {
    display: flex;
    flex-direction: row;
    box-shadow: 0 2px 5px gray;
}

.header_bar > div {
    margin: 20px;
}

* {
    /*font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;*/
    /*font-family: "SF Pro SC", "SF Pro Text", "SF Pro Icons", "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif;*/
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;

    font-size: 17px;
    font-style: normal;
}

a {
    text-decoration: none;
}

textarea {
    min-width: 400px;
    min-height: 300px;
}