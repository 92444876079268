.fn_detail_content {
    padding: 20px;
}

.fn_detail_content > * {
    margin: 10px;
    width: auto;
}
.detail_code{
    border: black 1px solid;
    border-radius: 5px;
    padding: 20px;
}

.fn_edit_content {
    display: flex;
    flex-direction: column;
}

.fn_content {
    display: flex;
    flex-direction: column;
    /*border-style: solid;*/
    /*border-width: thin;*/
    /*border-color: #dadce0;*/
    /*border-radius: 8px;*/
    /*padding: 40px 20px;*/
    margin: 10px;
}

.fn_content > * {
    margin: 10px;
    width: auto;
}


.fn_save_iframe {
    border: none;
    height: 50px;
}

.fn_editor {
    min-height: 200px;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
}
